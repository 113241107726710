import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LocalStorageService } from '../../shared/services/localStorage.service'
import { Router } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { AdminProvider } from '@modeso/tsf-lib-admin-fe';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewChecked {

  private subscriptions = new Array<Subscription>();
  isUserLoggedIn: boolean = false;

  constructor(
    private router: Router,
    private adminProvider: AdminProvider,
    private localStorageService: LocalStorageService,
    private changeDetector: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    this.isUserLoggedIn = this.localStorageService.isUserAuthorized();
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  onLogout() {
    this.subscriptions.push(
      this.adminProvider.logout$().pipe(take(2)).subscribe((response) => {
        if (response) {
          this.adminProvider.resetStore();
          this.localStorageService.removeAuthorization();
          this.router.navigate(['/login']);
        }
      })
    );
  }

}
